var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"mt-10",attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center title"},[_vm._v("Resources")])]),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}}),_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"8","sm":"12","xs":"12"}},[_c('v-row',_vm._l((_vm.resourceFolders),function(item,index){return _c('v-col',{attrs:{"Key":index,"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('v-card',{on:{"click":function($event){return _vm.displayResource(item.template)}}},[(item.title == 'Video' || item.title == 'Video')?_c('p',{staticClass:"text-center title pt-10 pb-10"},[_vm._v(" "+_vm._s(_vm.$t("resources.video"))+" ")]):(item.title == 'Files' || item.title == 'Filer')?_c('p',{staticClass:"text-center title pt-10 pb-10"},[_vm._v(" "+_vm._s(_vm.$t("resources.files"))+" ")]):(
                item.title == 'External Links' || item.title == 'Annet'
              )?_c('p',{staticClass:"text-center title pt-10 pb-10"},[_vm._v(" External Links ")]):_c('p',[_vm._v("...")]),_c('v-divider',{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},style:(("background-color:" + (_vm.colorArr[index]))),attrs:{"width":"98%"}}),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"padding-top":"0.1px !important"},attrs:{"cols":"12"}})],1)],1)}),1),_c('v-divider',{staticClass:"mt-10"}),_c('v-divider'),_c('v-divider')],1),_c('v-col',{staticClass:"pt-5 mb-15",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[(_vm.resourceTemplate == 0)?[_c('v-col',{attrs:{"cols":"12"}})]:(_vm.resourceContent == '')?[_c('v-col',{attrs:{"cols":"12","xl":"7","lg":"7","md":"7","sm":"12","xs":"12"}},[_c('p',{staticClass:"text-end title"},[_vm._v("Sorry no content added yet")])]),_c('v-col',{staticClass:"d-flex align-end flex-column",attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('v-btn',{staticClass:"mr-5",on:{"click":function($event){return _vm.closeTheResourceBox()}}},[_vm._v("X")])],1)]:(_vm.resourceContent)?[_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}}),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","xl":"5","lg":"5","md":"5","sm":"12","xs":"12"}},[(_vm.resourceTemplate == 1)?_c('p',{staticClass:"title"},[_vm._v("Video")]):(_vm.resourceTemplate == 2)?_c('p',{staticClass:"title"},[_vm._v("Files")]):(_vm.resourceTemplate == 6)?_c('p',{staticClass:"title"},[_vm._v(" External Link ")]):_vm._e()]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}}),_vm._l((_vm.resourceContent),function(resources,resourceIndex){return [(
                  (_vm.resourceTemplate == 1) &
                  (resources.type == 'youtube_video')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5 pb-1",attrs:{"elevation":hover ? 12 : 2,"href":resources.content,"target":"_blank","height":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"40px"},attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),(resources.language == 'Norsk')?_c('v-img',{staticClass:"mb-1",attrs:{"src":"/flags/norway.png","alt":"Norwegian Flag","max-height":"38","max-width":"60","contain":""}}):(resources.language == 'English')?_c('v-img',{staticClass:"mb-1",attrs:{"src":"/flags/england.png","alt":"English Flag","max-height":"38","max-width":"60","contain":""}}):_vm._e()],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":resources.thumbnail_url,"height":"300","width":"450","contain":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.width <= 959)?_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"16px","height":"40px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]):(
                              _vm.$vuetify.breakpoint.width <= 1300 &&
                              _vm.$vuetify.breakpoint.width >= 960
                            )?_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"16px","height":"80px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]):_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"18px","height":"80px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]),_c('p',{staticClass:"resourceDescription",staticStyle:{"font-size":"16px","height":"100px"}},[_vm._v(" "+_vm._s(resources.description)+" ")])]),_c('v-col',{staticClass:"pt-0 mt-0 pb-0",attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"pt-0 mt-0"},[_c('p',{staticClass:"rssCardButton mr-2 pt-3"},[_vm._v("See video")]),_c('v-icon',{staticClass:"rssCardButtonArrow"},[_vm._v("mdi-arrow-right")])],1)],1)],1),_c('v-divider',{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},attrs:{"width":"98%"}})],1)]}}],null,true)})],1)]:(
                  (_vm.resourceTemplate == 2) & (resources.type == 'files')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('pre',[_vm._v(_vm._s(resources))])])]:(
                  (_vm.resourceTemplate == 3) & (resources.type == 'courses')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('pre',[_vm._v(_vm._s(resources))])])]:(
                  (_vm.resourceTemplate == 4) & (resources.type == 'podcast')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('pre',[_vm._v(_vm._s(resources))])])]:(
                  (_vm.resourceTemplate == 5) & (resources.type == 'sound')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('pre',[_vm._v(_vm._s(resources))])])]:(
                  (_vm.resourceTemplate == 6) &
                  (resources.type == 'external_content')
                )?[_c('v-col',{key:resourceIndex,attrs:{"cols":"12","xl":"3","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5 pb-1",attrs:{"elevation":hover ? 12 : 2,"href":resources.content,"target":"_blank"}},[_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"40px"},attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"ma-0 pa-0"},[_c('v-spacer'),(resources.language == 'Norsk')?_c('v-img',{staticClass:"mb-1",attrs:{"src":"/flags/norway.png","alt":"Norwegian Flag","max-height":"38","max-width":"60","contain":""}}):(resources.language == 'English')?_c('v-img',{staticClass:"mb-1",attrs:{"src":"/flags/england.png","alt":"English Flag","max-height":"38","max-width":"60","contain":""}}):_vm._e()],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.width <= 959)?_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"16px","height":"20px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]):(
                              _vm.$vuetify.breakpoint.width <= 1300 &&
                              _vm.$vuetify.breakpoint.width >= 960
                            )?_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"14px","height":"80px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]):_c('p',{staticClass:"resourceCardTitle",staticStyle:{"font-size":"18px","height":"90px"}},[_vm._v(" "+_vm._s(resources.title)+" ")]),_c('p',{staticClass:"resourceDescription",staticStyle:{"height":"90px"}},[_vm._v(" "+_vm._s(resources.description)+" ")])]),_c('v-col',{staticClass:"pt-0 mt-0 pb-0",attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"pt-0 mt-0"},[_c('p',{staticClass:"rssCardButton mr-2 pt-3"},[_vm._v(" Les Artikkel ")]),_c('v-icon',{staticClass:"rssCardButtonArrow"},[_vm._v("mdi-arrow-right")])],1)],1)],1),_c('v-divider',{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},attrs:{"width":"98%"}})],1)]}}],null,true)})],1)]:_vm._e()]})]:_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
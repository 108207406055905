<template>
  <div class="homePageWidth">
    <MainCourseDataDialog ref="openThisCourseDataDialog"></MainCourseDataDialog>
    <MainTrainingDialogPhone
      ref="openThisCourseDataDialogPhone"
    ></MainTrainingDialogPhone>
    <v-row>
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <p class="trainingChapterTitle">Cooperation in Competition e-course</p>

        <!-- <pre>{{ filteredCategories }}</pre> -->
      </v-col>

      <v-col cols="12" class="mb-5">
        <v-card flat>
          <v-row>
            <template v-for="(courseCollection, index) in filteredAndSortedCourses">
              <v-col :key="index" cols="12" lg="3" md="3" class="mt-3">
                <!-- <template v-if="courseLanguage == courseCollection.language"> -->
                <template>
                  <v-card
                  v-if="$vuetify.breakpoint.mdAndDown"
                  height="100%"
                  @click="
                      $refs.openThisCourseDataDialogPhone.openDialog(
                        courseCollection
                        )
                        "
                  >
                  <v-img
                  v-if="courseCollection.background_image"
                  :src="courseCollection.background_image"
                  ></v-img>
                  <p class="pa-2 pb-0">{{ courseCollection.name }}</p>
                    <p class="pa-2 pt-0">{{ courseCollection.description }}</p>
                  </v-card>
                  <v-card
                    v-else
                    height="100%"
                    @click="
                      $refs.openThisCourseDataDialog.openDialog(
                        courseCollection
                        )
                        "
                  >
                    <v-img
                    v-if="courseCollection.background_image"
                    :src="courseCollection.background_image"
                    ></v-img>
                    <p class="pa-2 pb-0">{{ courseCollection.name }}</p>
                    <p class="pa-2 pt-0">{{ courseCollection.description }}</p>
                  </v-card>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MainCourseDataDialog from "@/components/versionThree/TrainingModuleDialog.vue";
import MainTrainingDialogPhone from "@/components/versionThree/TrainingModuleDialogPhone.vue";
export default {
  name: "Training-Modules",
  components: {
    MainCourseDataDialog,
    MainTrainingDialogPhone,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      courseCollections: [],
      customCourseOrder: [129, 153, 155, 154, 156],
      // get the selected language from localstorage key language
      // courseLanguage: localStorage.getItem("language"),
    };
  },
  mounted() {
    // this.checkIfLanguageExists();

    this.getAllCourses() ;
  },
  methods: {
    // Check if localstorage got a key named language and a value, if not then create one and set it to English
    checkIfLanguageExists() {
      // if (localStorage.getItem("language") == null) {
      //   localStorage.setItem("language", "English");
      // }
      this.getAllCourses();
    },

    getAllCourses() {
      this.courseCollections = [];
      this.$http
        .get(
          `https://app.followup.prios.no/api/courses/collection?tenant_id=114`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          console.log("SOMETHING", response.data);
          this.courseCollections = response.data;
        });
    },
    changeCourseLanguage(languageID) {
      this.courseLanguage = languageID;
    },

    // Update the custom course order
    updateCustomOrder(newOrder) {
      this.customCourseOrder = newOrder;
    },

  },
  computed: {
    filteredCategories() {
      const filteredCourses = this.courseCollections.filter((course) => {
        return course.public === 1;
      });
      return filteredCourses;
    },
    filteredAndSortedCourses() {
      // Sort the filteredCourses based on the custom order of IDs
      return this.filteredCategories.sort((a, b) => {
        const indexOfA = this.customCourseOrder.indexOf(a.id);
        const indexOfB = this.customCourseOrder.indexOf(b.id);

        // If both IDs are in the custom order, compare their positions
        if (indexOfA !== -1 && indexOfB !== -1) {
          return indexOfA - indexOfB;
        }

        // If only one of the IDs is in the custom order, prioritize it
        if (indexOfA !== -1) {
          return -1;
        }
        if (indexOfB !== -1) {
          return 1;
        }

        // If neither ID is in the custom order, maintain their original order
        return 0;
      });
    },
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

.trainingChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.trainingChapterDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
</style>

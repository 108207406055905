O
<template>
  <v-app-bar app class="navbarStyling" flat>
    <ContactForm ref="OpenContactForm"></ContactForm>
    <EnrollDialog ref="openEnrollDialog"></EnrollDialog>

    <!-- <p v-if="$i18n.locale == 'en'">asd</p> -->
    <!-- Logo -->
    <!-- <div class="d-flex align-center">
      <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="img/DIGI_logo.png" transition="scale-transition" width="40"/>
      <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100"/>
    </div> -->
    <!-- <v-img src="img/DIGI_logo.png" height="40" width="260px" contain/> -->
    <v-spacer></v-spacer>
    <a href="/">
      <v-img
        v-if="$vuetify.breakpoint.mdAndDown"
        class="mt-3 mb-3"
        src="img/young-wax-logo2.png"
        max-height="150"
        max-width="150"
        contain
      ></v-img>
      <v-img
        v-else
        class="mt-6"
        src="img/young-wax-logo2.png"
        max-height="90"
        max-width="90"
        contain
      ></v-img>
    </a>
    <v-spacer></v-spacer>
    <!-- Links -->
    <!-- 
      1. Logo
      2. Home Button
      3. About
      4. News
      5. Training Modules
      6. Digital Learning Design
      7. Digital Pedagogical Strategy
      8. Contact (dialog)
      9. Login (Link to followup? OR same as valuable creativity) 
    -->
    <div class="mt-10">
      <a href="/">Home</a>
      <a href="/about">About</a>
      <a href="/partners">Partners</a>
      <!-- <a href="/news">News</a> -->
      <!-- <a href="/rss">RSS</a> -->
      <!-- <a href="/mappingtool">Mapping Tool</a> -->
      <!-- <a href="/handbook">Handbook</a> -->
      <a href="/mooc" class="mr-2">Mooc</a>
      <!-- <a href="/resources" class="mr-2">Resources</a> -->

      <!-- <v-btn text to="/">{{ $t('navbar.home') }}</v-btn>
      <v-btn text to="/about">{{ $t('navbar.about') }}</v-btn>
      <v-btn text to="/news">{{ $t('navbar.news') }}</v-btn>
      <v-btn text to="/trainingmodules">Training Modules</v-btn>
      <v-btn text to="/resources">Resources</v-btn> -->
      <!-- <v-btn text to="/digitallearningdesign">Digital Learning Design</v-btn> -->
      <!-- <v-btn text to="/digitalpedagogicalstrategy">Digital Pedagogical Strategy</v-btn> -->
      <!-- <v-btn class="contactButton" small text @click="$refs.OpenContactForm.openContactDialog()">Contact</v-btn> -->
      <span
        class="contactButton"
        @click="$refs.OpenContactForm.openContactDialog()"
        >Contact</span
      >
      <!-- <v-btn icon @click="$refs.OpenContactForm.openContactDialog()">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn> -->
    </div>

    <v-spacer></v-spacer>
    <div class="mt-10">
      <LanguageSelection></LanguageSelection>
    </div>
    <v-btn
      v-if="userIsLoggedIn == false"
      text
      @click="$refs.openEnrollDialog.openDialog(true)"
      class="mt-10"
    >
      <v-icon small>mdi-lock</v-icon>
      Login
    </v-btn>
    <v-btn v-else @click="userLogout()" text class="mt-10">
      <v-icon small>mdi-lock</v-icon>
      Logout
    </v-btn>
    <!-- Login -->
    <!-- <div class="mt-7">
      <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">Login</v-btn>
    </div> -->
  </v-app-bar>
</template>

<script>
import ContactForm from "@/components/Global/ContactUs.vue";
import LanguageSelection from "@/components/TestAndDevelopment/SelectLocale.vue";
import EnrollDialog from "@/components/Login/enrollDialog.vue";

export default {
  components: {
    ContactForm,
    LanguageSelection,
    EnrollDialog,
  },
  data() {
    return {
      userIsLoggedIn: "",
    };
  },
  mounted() {
    this.checkIfLoggedIn();
  },
  methods: {
    // * Check if logged in, and getting user data
    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
        this.userIsLoggedIn = true;
        // setTimeout(() => {  location.reload(); }, 100);
      } else {
        this.userIsLoggedIn = false;
      }
    },
    // * User Logout - to home page and reload page *
    userLogout() {
      localStorage.clear();
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 100);
    },
  },
};
</script>

<style scoped>
.navbarStyling {
  height: 6em !important;
  width: 90vw !important;
  margin: auto auto !important;
  background-color: #ffffff !important;
  border-bottom: 5px solid #2a4b7f !important;
}
a {
  padding: 14px;
  color: #141428;
  text-decoration: none;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
}
a:visited {
  color: #141428;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
/* Contact Button */
.contactButton {
  font-family: "Lato", sans-serif;
  font-weight: regular;
  color: #141428;
  line-height: 1em;
  font-size: 16px;
}

.contactButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<template>
  <div>
    <ContactUs ref="OpenContactForm"></ContactUs>
    <EnrollDialog ref="openEnrollDialog"></EnrollDialog>
    <!-- Navbar -->
    <v-app-bar app color="light-blue lighten-5" style="height: 6em">
      <a href="/">
        <v-img
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-7"
          src="img/young-wax-logo2.png"
          max-height="90"
          max-width="90"
          contain
        ></v-img>
        <v-img
          v-else
          class="mt-7"
          src="img/young-wax-logo2.png"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </a>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleNavbar()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn icon @click="$refs.OpenContactForm.openContactDialog()">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="userIsLoggedIn == false"
        text
        @click="$refs.openEnrollDialog.openDialog(true)"
      >
        <v-icon small>mdi-lock</v-icon>
        Login
      </v-btn>
      <v-btn v-else @click="userLogout()" text>
        <v-icon small>mdi-lock</v-icon>
        Logout
      </v-btn>
    </v-app-bar>
    <!-- Navbar -->
    <v-navigation-drawer v-model="navDrawer" app>
      <a href="/">
        <v-img
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-5 mb-5 ml-10"
          src="img/young-wax-logo2.png"
          max-height="90"
          max-width="90"
          contain
        ></v-img>
        <v-img
          v-else
          class="mt-5"
          src="img/young-wax-logo2.png"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </a>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in pageNavigation"
          :key="index"
          :to="item.location"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="$refs.OpenContactForm.openContactDialog()">
          <v-list-item-icon>
            <v-icon>mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <!-- <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">Login</v-btn> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import ContactUs from "@/components/Global/ContactUs.vue";
import EnrollDialog from "@/components/Login/enrollDialog.vue";
export default {
  components: {
    ContactUs,
    EnrollDialog,
  },
  data() {
    return {
      userIsLoggedIn: "",
      navDrawer: true,
      expand: true,
      pageNavigation: [
        { name: "Home", location: "/", icon: "" },
        { name: "About", location: "/about", icon: "" },
        { name: "Partners", location: "/partners", icon: "" },
        // { name: "News", location: "/news", icon: "" },
        // { name: "RSS", location: "/rss", icon: "" },
        // { name: "Mapping Tool", location: "/mappingtool", icon: "" },
        // { name: "Handbook", location: "/handbook", icon: "" },
        { name: "Mooc", location: "/mooc", icon: "" },
        // { name: "Resources", location: "/resources", icon: "" },
      ],
    };
  },
  mounted() {
    this.checkIfLoggedIn();
  },

  methods: {
    // Toggles the sidebar on and offs
    toggleNavbar() {
      if (this.navDrawer == true) {
        this.navDrawer = false;
      } else {
        this.navDrawer = true;
      }
    },
    // * Check if logged in, and getting user data
    checkIfLoggedIn() {
      let userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
        this.userIsLoggedIn = true;
        // setTimeout(() => {  location.reload(); }, 100);
      } else {
        this.userIsLoggedIn = false;
      }
    },
    // * User Logout - to home page and reload page *
    userLogout() {
      localStorage.clear();
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 100);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.fontTitle {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
.fontSubTitle {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
}
.fontContent {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
}
p {
  font-family: "Source Sans Pro", sans-serif;
}
</style>

<template>
  <div class="homePageWidth">
    <v-row>
      <!-- ===== About Company ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12">
            <p class="aboutTitle">About Young Wax on the Market</p>
            <p class="aboutDescription">
              The importance of digital marketing has gradually increased with the effect of restrictions during the COVID-19 crisis. <br>
              Entrepreneurs who were able to use the digital market in this process turned this crisis into an opportunity. <br>
              In this process, it has become essential to support young people, students and job seekers with free materials on e-commerce. <br>
              As it is known, the biggest problem encountered in e-commerce is delivering the right goods to the right audiences at the right time. <br>
              By teaching young people market research and analysis, we will enable them to reach their target audiences more easily. <br>
              With this project, we aim to enable economically disadvantaged young people to create their own Digital Marketing Strategies.
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- ===== Aims and Results ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
            <v-row>
              <!-- Main Aim -->
              <v-col cols="12" >
                <p class="goalTitle">Main aim</p>
                <p class="goalDescription">
                  It is to support actions aimed at improving the digital skills and competencies of young people, students, job seekers and employees who have fewer opportunities in social and economic life.
                </p>
              </v-col>
              <!-- Aims -->
              <v-col cols="12" class="mb-15">
                <p class="goalTitle">Aims</p>
                <p class="goalDescription">
                  <strong>The project aims to: </strong> -To increase the capacity of young people how to take advantage of the opportunities offered by digital technologies in the field of e-commerce <br>
                  - To develop the competencies of economically disadvantaged young people in the field of e-commerce and to support their entrepreneurship<br>
                  - To create resources to guide disadvantaged youth in the field of e-commerce
                </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- ===== Results ===== -->
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <p class="goalTitle">Results</p>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in results" :key="index">
                <v-expansion-panel-header>
                  <p class="goalDescription" style="font-weight:bold;">{{item.title}}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre class="goalDescription" style="white-space: pre-wrap;">{{item.description}}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'About Page',
  components:{

  },
  data(){
    return {
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" }
      ],
      results: [
        {
          title: "PR 1 - Online training program",
          description: `A standard online training program on E-Commerce for disadvantaged youth, prepared with partner organizations. The content of the program is as follows:
THEME-1: Introduction
THEME-2:Website & Marketplaces 
THEME-3: Simply Establishing a Company 
THEME-4: Let's Sell:
THEME-5: Product Price & Supply Analysis 
THEME-6: Final (A Sample Application). 
This will be an e-learning curriculum for aspiring entrepreneurs who want to get into E-Commerce.`
        },
        {
          title: "PR 2 - Market Analysis Report",
         description: `Market analysis and research are one of the things that young entrepreneurs, our target audience, should do while preparing to enter the digital market. Because market research enables entrepreneurs to identify potential businesses and avoid potential mistakes. Market research will be exemplary research that guides young entrepreneurs to solve the problems that may arise while making products, target audience, market and pricing in e-commerce.
This output will also cover the following topics:
How is the market analysis done?
1. Determining the Purpose
2. Investigation of the Industry Situation 3. Determining the Target Audience
4. Recognition of Competitors
5. Pricing and Estimation
6. Making Environmental Analysis
7. SWOT Analysis
In addition, this output will be made available to the beneficiaries free of charge on the project website and the websites of the institutions.`
        },
        {
          title: "PR 3 - E-Handbook on E-commerce",
         description: `A handbook will be prepared with the support of partners. It will be translated into the languages of the partner countries and into English. The institution responsible for this activity is Nazilli Public Education Center.
CONTENTS:
The content of this handbook will be as follows:
1- Introduction and summary of the project
2- Promotion of e-commerce for young entrepreneurs 3- E-commerce websites and marketplace information 4- Steps of establishing a company in a simple way
5- Points to be considered in product sales
6- Product Price & Supply Analysis
7- Examples of Good Practice in Europe
8- An example application`
        },
      ]
    }
  }
}
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw; 
  margin:auto auto;
}

/* About Company */
.aboutTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6A6A6A;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: center; 
  background-color: #E0F1FF;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}
.whomBackground {
  background-color: #F2F2F2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6A6A6A;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #D1D1D1;
  opacity: 1;
}

</style>
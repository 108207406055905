<template>
  <div class="homePageWidth">
    <v-col cols="12" class="mt-10">
      <!-- <p class="title text-center">Mooc</p> -->
    </v-col>
    <TrainingModulePage></TrainingModulePage>
  </div>
</template>

<script>
// import TrainingModulePage from "@/components/TrainingModule/TrainingDataTwo.vue";
import TrainingModulePage from "@/components/versionThree/TrainingModuleVersionThree.vue";
export default {
  name: "Training-Modules",
  components: {
    TrainingModulePage,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}
</style>

<template>
  <div>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="ma-0 pa-0 pt-16 mt-16">
        <v-card class="ma-0 pa-0 box" flat>
          <p class="ma-0 pa-0 partnerChapterTitle">Partners</p>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-for="(item, index) in projectPartners" :key="index">
        <v-card class="cardBorder ma-2 pa-7 px-2"  height="100%">
          <v-row>
            <v-col cols="12">
              <v-card height="90px" flat class="d-flex" :href="item.link" target="_blank">
                <v-img class="mt-5" :src="item.image" contain />
              </v-card>
            </v-col>
            <v-col class="pb-0 mb-0" cols="12">
              <p class="pl-3 partnerTitle">{{item.title}} 
                <span> 
                  <v-btn icon :href="item.link" target="_blank">
                    <v-icon color="green">mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </p>
            </v-col>
            <v-col v-if="selectedIndex !== index" cols="12" class="ma-0 pa-0 pl-3 pr-3">
              <p class="pl-3 pr-3 partnerDescriptionPre">{{item.description}}</p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon v-if="selectedIndex !== index" @click="openingPartnerExpand(index)">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon v-else @click="closingPartnerExpand()">
              <v-icon large color="black">mdi-chevron-up</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="index === selectedIndex">
              <v-divider></v-divider>
              <v-card-text>
                <p class="partnerDescriptionPost">{{item.description}}</p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data(){
    return {
      selectedIndex: null,
      projectPartners: [
        { 
          title: "Nazhem", 
          image: "img/Nazilli.jpg",
          link: "https://nazillihem.meb.k12.tr/",
          description: `Nazilli Public Education Center is a governmental adult education center serving 5000 trainees with approximately 100 trainers per year. 
The center has been the only state Public Education Center serving in Nazilli since 1973. 
With some courses on entrepreneurship, handicrafts, sports, arts, music, language, computers, literacy, sports, traditional-modern arts, and theater, 
the center aims to apply non-formal education programs for people of all ages and all levels of education to increase their life skills in learning and business activities. 
It also works in partnership with other non-governmental organizations to increase the quality of life of the target group, to increase the welfare of the society, and reveal their energies and creativity. 
Over time, the institution has developed itself in the branches of literacy courses, vocational and technical courses, social and cultural courses, handy crafts, and traditional arts.`, 
        },
        { 
          title: "MIHI", 
          image: "img/Mihi2.png",
          link: "https://lv.mihiyouth.org/",
          description: `MI-HI (Make It Happen Infinity) is a youth organization that aims to empower youth and facilitate personal development.It supports youth exchanges, educational events, 
cultural and language training, volunteering opportunities, environmental activism, and youth mobility.
Since 2019, MI-HI has helped many youths engage in various development projects. 
These project themes are covered through general training workshops and conferences. 
They include but are not limited to: art and culture, entrepreneurship, intercultural communication, heritage protection, volunteering, environmental protection, equal opportunities, anti-racism, 
healthy lifestyles, human rights, youth initiatives, youth exchanges, youth employment, youth sports, youth migration, social media, social work, peacebuilding, digital youth work, and youth leadership.`, 
        },
        { 
          title: "Prios", 
          image: "img/Prios.webp",
          link: "https://www.prios.no/en-gb",
          description: `Prios Kompetanse AS (Prios) is a research-based corporation established by several collaborative competence environments. 
Main tasks are project management, business consulting, innovation processes, training and software development. 
Prios as research center aims to implement the idea of lifelong learning, support and conduct development projects.`, 
        },
      ]
    }
  },
  methods: {
    // Open the Expand
    openingPartnerExpand(index){
      this.selectedIndex = index;
    },
    // Close the Expand
    closingPartnerExpand(){
      this.selectedIndex = null;
    },
  }
}
</script>

<style scoped>
/* Card CSS */
.box{
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right,  #DD9A30 5%, transparent 5%);
  border-image-slice: 1;
}
.cardBorder{
  border: 3px #47b5b0 solid;
}


/* Text styling */
.partnerChapterTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.partnerTitle {
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #141428;
  letter-spacing: 0px;
  text-align: left;
}
.partnerDescriptionPre {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #141428;
  opacity: 1;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  text-align: left;
  color: #141428;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0px;
}
</style>